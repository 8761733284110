import React                from "react";
import PropTypes            from "prop-types";

// Styled
import Styled, {
    keyframes, css,
} from "styled-components";



// Animations
const open = keyframes`
    from { opacity: 0; }
    50%  { opacity: 1; }
`;
const close = keyframes`
    50%  { opacity: 1; }
    to   { opacity: 0; }
`;

// Styles
const Container = Styled.div.attrs(({ isClosing }) => ({ isClosing }))`
    display: flex;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100dvh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-color: var(--backdrop-color);
    z-index: var(--z-backdrop);

    ${(props) => props.isClosing ?
        css`animation: ${close} 0.3s ease-out both;` :
        css`animation: ${open} 0.3s ease-in both;`};

    @media (width: 400px) {
        inset: 15px;
        border-radius: var(--border-radius);
    }
`;



/**
 * The Backdrop
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Backdrop(props) {
    const { isClosing, onClick, children } = props;

    // Do the Render
    return <Container isClosing={isClosing} onMouseDown={onClick}>
        {children}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Backdrop.propTypes = {
    isClosing : PropTypes.bool.isRequired,
    onClick   : PropTypes.func.isRequired,
    children  : PropTypes.any,
};

export default Backdrop;
