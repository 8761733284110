import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Header               from "Components/Core/Header";
import Floater              from "Components/Core/Floater";
import Footer               from "Components/Core/Footer";
import Home                 from "Components/Page/Home";
import Product              from "Components/Page/Product";
import Category             from "Components/Page/Category";
import Search               from "Components/Page/Search";
import Cart                 from "Components/Page/Cart";
import Settings             from "Components/Page/Settings";



// Styles
const Container = Styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100dvh;
    font-family: var(--store-font);

    @media (max-width: 600px) {
        --main-padding: 12px;
    }
    @media (max-width: 400px) {
        --product-gap: 12px;
    }
`;

const Content = Styled.div`
    box-sizing: border-box;
    flex-grow: 2;
    width: 100%;
    height: auto;
    max-width: var(--main-width);
    margin: 0 auto;
    padding: 0 var(--main-padding);

    @media (max-width: 600px) {
        padding: 0 var(--main-padding);
    }
`;



/**
 * The App
 * @returns {React.ReactElement}
 */
function App() {
    const {
        currentCategory, currentSearch, currentProduct,
        showCart, showSettings, options,
    } = Store.useState();


    // The custom colors
    const style = {
        "--store-font"        : `${options.fontFamily || "Inter"}, "Helvetica Neue", Helvetica, Arial, sans-serif`,
        "--store-header-bg"   : options.headerBgColor,
        "--store-header-text" : options.headerTextColor,
        "--store-search-bg"   : options.searchBgColor,
        "--store-search-text" : options.searchTextColor,
        "--store-button-bg"   : options.buttonBgColor,
        "--store-button-text" : options.buttonTextColor,
        "--store-buy-bg"      : options.buyBgColor,
        "--store-buy-text"    : options.buyTextColor,
    };

    // Variables
    const showSearch   = Boolean(currentSearch);
    const showCategory = Boolean(!showSearch && currentCategory);
    const showProduct  = Boolean(currentProduct);
    const showHome     = !showCategory && !showSearch;


    // Scroll to Top
    React.useEffect(() => {
        window.scrollTo({ top : 0, behavior : "instant" });
    }, [ currentCategory, currentSearch ]);


    // Do the Render
    // @ts-ignore
    return <Container style={style}>
        <Header />
        <Content>
            {showHome && <Home />}
            {showCategory && <Category />}
            {showSearch && <Search />}
        </Content>
        <Floater />
        <Footer />
        {showProduct && <Product />}
        {showCart && <Cart />}
        {showSettings && <Settings />}
    </Container>;
}

export default App;
