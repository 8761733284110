import React                from "react";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Icon                 from "Components/Utils/Icon";
import Button               from "Components/Utils/Button";
import Html                 from "Components/Utils/Html";

// Styled
import Styled, {
    keyframes,
} from "styled-components";



// Animations
const animation = keyframes`
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0.9, 0.9);
    }
`;

// Styles
const Container = Styled.footer.attrs(({ showCart }) => ({ showCart }))`
    position: fixed;
    bottom: 0;
    right: calc((100vw - 800px) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0 0 24px 0;
    z-index: 20;

    ${(props) => props.showCart && "pointer-events: none;"}

    @media (max-width: 800px) {
        right: var(--main-padding);
    }
`;

const Buy = Styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 12px;
    color: var(--store-buy-text);
    font-size: 16px;
    border: none;
    border-radius: var(--border-radius-small);
    background-color: var(--store-buy-bg);
    box-shadow: var(--box-shadow);
    cursor: pointer;

    .icon {
        font-size: 18px;
        margin-bottom: -2px;
    }

    &:focus {
        animation: ${animation} 0.5s ease-in-out backwards;
    }
    &:active {
        animation: none;
    }
`;

const Amount = Styled.div`
    position: absolute;
    height: 20px;
    right: -2px;
    top: -10px;
    width: 20px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background: red;
    color: white;
    border-radius: 100px;
`;



/**
 * The Floater
 * @returns {React.ReactElement}
 */
function Floater() {
    const { currentProduct, showCart, cartAmounts, options } = Store.useState();

    const getProduct     = Store.useGetProduct();
    const showCartToggle = Store.useShowCart();
    const getText        = Store.useText();


    // Variables
    const showPrices = Utils.isActive(options.pricesInCart);
    const showTop    = Boolean(!currentProduct && !showCart);


    // Handles the Scroll to Top
    const handleScroll = () => {
        window.scrollTo({ top : 0, behavior : "smooth" });
    };

    // Calculates the total
    const [ text, amount ] = React.useMemo(() => {
        let totalAmount = 0;
        let totalPrice  = 0;

        for (const [ productID, amount ] of Object.entries(cartAmounts)) {
            if (amount > 0) {
                totalAmount += amount;
                totalPrice  += getProduct(productID).price * amount;
            }
        }

        if (!totalAmount) {
            return [ getText("emptyCart"), 0 ];
        }
        if (showPrices) {
            return [ Utils.formatPrice(totalPrice), totalAmount ];
        }
        if (totalAmount > 1) {
            return [ `${totalAmount} ${getText("products")}`, 0 ];
        }
        return [ `${totalAmount} ${getText("product")}`, 0 ];
    }, [ showPrices, JSON.stringify(cartAmounts) ]);


    // Do the Render
    return <Container showCart={showCart}>
        <Button
            isHidden={!showTop}
            icon="up"
            onClick={handleScroll}
            isPrimary
        />

        <Buy onClick={() => showCartToggle(true)}>
            <Icon icon="product" />
            <Html>{text}</Html>
            {amount > 0 && <Amount>{amount}</Amount>}
        </Buy>
    </Container>;
}

export default Floater;
