import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Html                 from "Components/Utils/Html";
import Button               from "Components/Utils/Button";



// Styles
const Container = Styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    width: var(--product-width);
    flex-shrink: 0;
    padding: 16px 16px 16px 24px;
    border-radius: var(--border-radius);
    background-color: white;
    box-shadow: var(--box-shadow);
    font-size: 12px;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 400px) {
        padding: var(--main-padding);
    }
`;

const Image = Styled.img`
    width: 140px;
    height: 140px;
    margin: 0 auto;
    max-width: 100%;
    object-fit: cover;
`;

const Content = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Title = Styled.h3`
    margin: 0;
`;

const Description = Styled(Html)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    color: rgb(24, 45, 103);
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    :empty {
        display: none;
    }
`;

const Footer = Styled.footer`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
`;

const Price = Styled(Html)`
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
`;



/**
 * The Product
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Product(props) {
    const { elem } = props;

    const { options } = Store.useState();

    const [ amount, update ] = Store.useAddCart(elem.id);
    const showProduct    = Store.useShowProduct();
    const getProductText = Store.useProductText();


    // Variables
    const showPrices = Utils.isActive(options.pricesInLists);
    const hasAmount  = amount > 0;
    const name       = getProductText(elem.id, "name");


    // Handles the Product
    const handleProduct = () => {
        showProduct(elem.id);
    };

    // Handles the Add
    const handleAdd = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "contextmenu") {
            update(-1);
        } else {
            update(1);
        }
    };

    // Gets the Price
    const price = React.useMemo(() => {
        if (showPrices) {
            return Utils.formatPrice(elem.price);
        }
        return " ";
    }, [ showPrices, elem.price ]);


    // Do the Render
    return <Container onClick={handleProduct}>
        <Content>
            {elem.hasImage && <Image src={elem.imageUrl} alt={name} />}
            <Title>{name}</Title>
            <Description
                content={getProductText(elem.id, "description")}
                addBreaks
            />
        </Content>
        <Footer>
            <Price>{price}</Price>
            <Button
                icon={hasAmount ? "" : "add"}
                message={hasAmount ? `x${amount}` : ""}
                isPrimary={!hasAmount}
                isSecondary={hasAmount}
                onClick={handleAdd}
                onContextMenu={handleAdd}
            />
        </Footer>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Product.propTypes = {
    elem : PropTypes.object.isRequired,
};

export default Product;
