import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Banners              from "Components/Core/Banners";
import Categories           from "Components/Core/Categories";
import Product              from "Components/Core/Product";
import Icon                 from "Components/Utils/Icon";



// Styles
const Header = Styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0 12px 12px;
`;

const Title = Styled.h2`
    margin: 0;
    font-size: 24px;
    cursor: pointer;
`;

const Link = Styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
        margin-top: 2px;
        line-height: 1;
        font-size: 20px;
    }
    :hover span:first-child {
        text-decoration: underline;
    }
`;

const List = Styled.section.attrs(({ rows, withSpacing }) => ({ rows, withSpacing }))`
    padding: 4px;
    gap: var(--product-gap);
    overflow-x: auto;

    ${(props) => props.withSpacing && "margin-top: 32px;"}

    ${(props) => props.rows === 0 ? `
        --product-width: calc((100% - var(--product-gap) * (var(--product-columns) - 1)) / var(--product-columns));

        display: flex;
        flex-wrap: wrap;

        @media (max-width: 600px) {
            --product-columns: 2;
        }
    ` : `
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(${props.rows}, 1fr);
        grid-auto-columns: var(--product-width);
    `}
`;



/**
 * The Home
 * @returns {React.ReactElement}
 */
function Home() {
    const { categories, products, options } = Store.useState();

    const showPage        = Store.useShowPage();
    const getText         = Store.useText();
    const getCategoryText = Store.useCategoryText();


    // Variables
    const showBanners         = Utils.isActive(options.showBanners);
    const showCategories      = Utils.isActive(options.categoriesInHome);
    const totalCategories     = Math.max(Number(options.totalCategories), 0);
    const productsPerCategory = Math.max(Number(options.productsPerCategory), 0);
    const rowsPerCategory     = Math.max(Number(options.rowsPerCategory), 0);


    // Calculates the List
    const list = React.useMemo(() => {
        if (!categories.length) {
            return products;
        }

        const result = [];
        for (const category of categories) {
            const items = products.filter(({ categoryID }) => categoryID === category.id);
            if (items.length) {
                result.push({
                    id    : category.id,
                    name  : getCategoryText(category.id, "name"),
                    items : productsPerCategory ? items.slice(0, productsPerCategory) : items,
                });
            }
            if (totalCategories && result.length >= totalCategories) {
                break;
            }
        }

        if (!totalCategories || result.length < totalCategories) {
            const items = products.filter(({ categoryID }) => categoryID === 0);
            if (items.length) {
                result.push({
                    id    : -1,
                    name  : getText("noCategory"),
                    items : productsPerCategory ? items.slice(0, productsPerCategory) : items,
                });
            }
        }
        return result;
    }, [ totalCategories, productsPerCategory ]);



    // Do the Render
    if (!categories.length) {
        return <List rows={0} withSpacing>
            {list.map((item) => <Product
                key={item.id}
                elem={item}
            />)}
        </List>;
    }

    return <>
        {showBanners && <Banners />}
        {showCategories && <Categories />}

        {list.map((elem) => <React.Fragment key={elem.id}>
            <Header>
                <Title onClick={() => showPage(elem.id)}>
                    {elem.name}
                </Title>
                <Link onClick={() => showPage(elem.id)}>
                    <span>{getText("showAll")}</span>
                    <Icon icon="next" />
                </Link>
            </Header>
            <List rows={rowsPerCategory}>
                {elem.items.map((item) => <Product
                    key={item.id}
                    elem={item}
                />)}
            </List>
        </React.Fragment>)}
    </>;
}

export default Home;
