/**
 * Returns true if the value is an Object
 * @param {*} value
 * @returns {Boolean}
 */
function isObject(value) {
    return typeof value === "object" && value !== null;
}

/**
 * Returns true if the Object is empty
 * @param {Object} object
 * @returns {Boolean}
 */
function isEmpty(object) {
    return !object || Object.keys(object).length === 0;
}

/**
 * Returns the Property as a boolean
 * @param {(Number|String)} property
 * @returns {Boolean}
 */
function isActive(property) {
    return Boolean(Number(property));
}

/**
 * Clones an Object
 * @param {Object} object
 * @returns {Object}
 */
function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * Creates an Array of N numbers
 * @param {Number}  amount
 * @param {Number=} start
 * @returns {Number[]}
 */
function createArrayOf(amount, start = 1) {
    const result = [];
    for (let i = start; i < amount + start; i++) {
        result.push(i);
    }
    return result;
}



/**
 * Restores an Item from Local Storage
 * @param {String} key
 * @param {String} defaultValue
 * @returns {String}
 */
function restoreItem(key, defaultValue = "") {
    try {
        const value = window.localStorage.getItem(key);
        return value || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 * Stores an Item in Local Storage
 * @param {String} key
 * @param {String} value
 * @returns {Boolean}
 */
function storeItem(key, value) {
    try {
        window.localStorage.setItem(key, value);
        return true;
    } catch (e) {
        return false;
    }
}



/**
 * Shortens the given Text if is too long
 * @param {String} text
 * @returns {String}
 */
function makeShort(text) {
    if (text.length > 100) {
        return text.substring(0, 100) + "...";
    }
    return text;
}



/**
 * Returns the Language from the Navigator
 * @param {Object[]} languages
 * @param {String}   defaultLanguage
 * @returns {String}
 */
function getLanguage(languages, defaultLanguage) {
    let language = restoreItem("conversana-language");

    if (!language && navigator.languages) {
        language = (navigator.languages && navigator.languages[0]) || navigator.language || "es";
        if (language.includes("-")) {
            language = language.split("-")[0];
        }
        language = language.toLowerCase();
    }

    for (const item of languages) {
        if (item.key === language) {
            return language;
        }
    }
    return defaultLanguage || "es";
}

/**
 * Returns the Current Time
 * @returns {Number}
 */
function getCurrentTime() {
    return Math.floor(new Date().getTime() / 1000);
}

/**
 * Returns the Time as a String
 * @param {Number}  time
 * @param {Boolean} withHour
 * @returns {String}
 */
function getDayString(time, withHour) {
    const options = { month : "short", day : "numeric" };
    const date    = new Date(time * 1000);
    const now     = new Date();

    if (withHour) {
        options.hour   = "numeric";
        options.minute = "numeric";
    }
    if (now.getFullYear() !== date.getFullYear())  {
        options.year = "numeric";
    }

    // @ts-ignore
    return date.toLocaleString(undefined, options);
}

/**
 * Creates a Hash with the given Hash
 * @param {Number} length
 * @returns {String}
 */
function createHash(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result  = "";
    let counter = 0;
    while (counter < length) {
        result  += characters.charAt(Math.floor(Math.random() * characters.length));
        counter += 1;
    }
    return result;
}

/**
 * Gives an html format to prices
 * @param {Number}   price
 * @param {Boolean=} skipZeros
 * @param {String=}  zeroStr
 * @param {String=}  symbol
 * @returns {String}
 */
function formatPrice(price, skipZeros = false, zeroStr = "", symbol = "$") {
    if (skipZeros && price === 0) {
        return zeroStr || "";
    }

    const sign       = price < 0 ? "-" : "";
    const positive   = Math.abs(price);
    const noCents    = Math.floor(positive);
    const noCentsStr = noCents.toLocaleString(window.navigator.language);
    const cents      = Math.round(positive * 100 - noCents * 100);
    const centsStr   = cents < 10 ? "0" + cents : String(cents);

    return `${symbol} ${sign}${noCentsStr}<sup>${centsStr}</sup>`;
}

/**
 * Parses the Fields
 * @param {Object} elem
 * @param {String} language
 * @param {String} viewFile
 * @param {Object} yesNo
 * @returns {Array<Object>}
 */
function parseFields(elem, language, viewFile, yesNo) {
    const fields = Object.values(elem.fields || {});
    const result = {};

    for (const field of fields) {
        if (field.isHidden) {
            continue;
        }
        if (field.tongueID && field.isoCode !== language && result[field.customFieldID]) {
            continue;
        }

        let value = field.value;
        switch (field.inputType) {
        case "toggle":
            value = yesNo[String(field.value || 0)];
            break;
        case "number":
            value = !field.value ? "0" : String(field.value);
            break;
        case "date":
            value = field.value.split("-").reverse().join("-");
            break;
        case "select":
            value = field.options[field.value - 1] || field.value;
            break;
        case "list":
            if (field.value) {
                value = JSON.parse(field.value).join(", ");
            }
            break;
        case "password":
            value = "**********";
            break;
        case "file":
            value = process.env.REACT_APP_INTERNALS + field.clientID + field.value;
            value = `<a class="link" href="${value}" target="_blank">${viewFile}</a>`;
            break;
        default:
        }

        result[field.customFieldID] = {
            id   : field.customFieldID,
            name : field.name,
            value,
        };
    }

    return Object.values(result);
}




// The public API
export default {
    isObject,
    isEmpty,
    isActive,
    clone,
    createArrayOf,

    restoreItem,
    storeItem,

    makeShort,
    getLanguage,
    getCurrentTime,
    getDayString,
    createHash,
    formatPrice,
    parseFields,
};
